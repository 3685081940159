import db from "../server/firebase";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Button } from '@material-ui/core';


export const Header = (props) => {
  const recaptchaRef = useRef();
  const [captcha, setCaptcha] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('david');
    //await recaptchaRef.current.execute();
    
    const token = await recaptchaRef.current.executeAsync();
    console.log(token); //value

    let addToDb = true;

    const recaptchaValue = recaptchaRef.current.getValue();
    console.log("recaptcha: ", recaptchaValue);
    //check for a valid email 

    /*
    if (!captcha) {
      addToDb = false;
    }*/
    
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(e.target[0].value) === false) {
      console.log("invalid email");
      addToDb = false;
    }
    //check 

    if (addToDb) {
      let submit_email = e.target[0].value;
      try {
        // const data = await axios.post("http://localhost:5001/cutterbutter-3dd75/us-central1/app/submit", { submit_email });
        const data = await axios.post("http://localhost:8000/submit", { submit_email });
        console.log(data); 
      } catch (e) {
        console.log(e, "error with axios");
      }
      
    }
     
  }

  function onChange(val) {
    // console.log(val);
    // console.log("onChange prop - Captcha value:", val);

    // if (val === null) setShowCaptcha(false);

    if (val !== null) {
      setCaptcha(true);
      axios.post('https://www.google.com/recaptcha/api/siteverify', {
        secret: "6Ld3zbkaAAAAAIOoMIbQIrMIKlYNK8R3UfDnVK6M",
        response: val,
      })
      .then(function (response) {
        console.log(response.success);
      })
      .catch(function (error) {
        console.log(error);
      })
    }
    else {
      setCaptcha(false);
    }
    //setCaptcha(true);
  }

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>

                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <h1>
                  
                </h1>
                <a href="https://apps.apple.com/us/app/stylex-hairstyle-network/id1566888276" target="_blank" rel='noreferrer' style={{ marginRight: 40 }}>
                  <img src={process.env.PUBLIC_URL + "/img/app store/apple.png"} alt="StyleX on Apple App Store" width="210" height="70" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.cutterbutternutter" target="_blank" rel='noreferrer'>
                  <img src={process.env.PUBLIC_URL + "/img/app store/google.png"} alt="StyleX on Google Play Store" width="250" height="70" />
                </a>
                {/* <form id="contactForm" onSubmit={handleSubmit}>
                  
                  <input type="text" name="name" placeholder="Email me when it launches"/>
                  <button type="submit">SEND</button>
                  <Button type="submit" variant="contained" color="primary" size="large">Submit</Button>
                  
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LdEAbsaAAAAAOEipCwKj9VaIcL0c1zhwKz_0Jwh"
                    onChange={onChange}
                    //style={{marginTop: 50, marginLeft: 225, marginRight: 225, itemAlign: "center", textAlign: "center" }}
                  />
                  
                  
                  
                  <a
                  href='#features'
                  className='btn btn-custom btn-lg page-scroll'
                  >
                    Submit
                  </a> 
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
