
import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyAk3GsTzfvsDqsPeFlj_w4X8PZT8NNqIi4",
    authDomain: "cutterbutter-3dd75.firebaseapp.com",
    databaseURL: "https://cutterbutter-3dd75-default-rtdb.firebaseio.com",
    projectId: "cutterbutter-3dd75",
    storageBucket: "cutterbutter-3dd75.appspot.com",
    messagingSenderId: "890331613965",
    appId: "1:890331613965:web:75aed80dbaa9dbc40ebe44",
    measurementId: "G-4PRHLSRZH3"
  };



const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
export default db;